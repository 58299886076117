<template>
  <div>
    <el-dialog :title="editDigTitle" width="800px" v-model="editDigShow" :close-on-click-modal="false">
      <el-form :model="warehouseInfo" ref="warehouseInfo" :rules="warehouseEditRules" class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="省份" label-width="100px" prop="province">
              <el-select @change="handler_changeProvince" v-model="warehouseInfo.province" placeholder="请选择省级地址">
                <el-option
                    v-for="item in selectProvinceList"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.areaName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="城市" label-width="100px" prop="city">
              <el-select @change="handler_changeCity" v-model="warehouseInfo.city" placeholder="请选择市级地址">
                <el-option
                    v-for="item in selectCityList"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.areaName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域" label-width="100px" prop="area">
              <el-select v-model="warehouseInfo.area" placeholder="请选择县区">
                <el-option
                    v-for="item in selectAreaList"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.areaName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址" label-width="100px" prop="address">
              <el-input v-model="warehouseInfo.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="editDigShow=false">取 消</el-button>
        <el-button v-if="auth['customerwarehouse:edit']" type="primary" @click="edit_save('warehouseInfo')">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import WAREHOUSE from '../../api/biz/api_customerwarehouse';
import AREA from "@/api/base/api_area";
import COMM from "@/api/base/api_comm";

export default {
  props: {
    editDigShow:{
      type: Boolean
    },
    editDigTitle: {
      type: String
    },
    warehouseInfo: {
      type: Object
    }
  },
  data(){
    return {
      MAP: undefined,

      warehouseEditRules: {
        province: [{required: true, message: '请选择省份', trigger: 'blur'}],
        city: [{required: true, message: '请选择客户类型！', trigger: 'blur'}]
      },
      selectProvinceList: [],
      selectCityList: [],
      selectAreaList: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.init_base();
    },

    // 加载初始数据支持
    init_base(){
      this.initcustomerInfoAddr();
    },

    edit_save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.warehouseInfo.id > 0) {
            WAREHOUSE.update(this.warehouseInfo).then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.$parent.editShow=false;
                this.$parent.initPageData();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(() => console.log('promise catch err'));
          } else {
            WAREHOUSE.add(this.warehouseInfo).then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.$parent.editShow=false;
                this.$parent.initPageData();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(() => console.log('promise catch err'));
          }

        } else {
          return false;
        }
      });
    },

    initcustomerInfoAddr() {
      this.initProvinceList();
      this.initCityList();
      this.initAreaList();
    },

    initProvinceList() {
      AREA.listRoot().then((res) => {
        if (200 == res.code) {
          this.selectProvinceList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    initCityList() {
      if (this.warehouseInfo?.province) {
        let params = { parentName: this.warehouseInfo.province };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectCityList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    initAreaList() {
      if (this.warehouseInfo?.city) {
        let params = { parentName: this.warehouseInfo?.city };
        AREA.listChildrenByParentName(params).then((res) => {
          if (200 == res.code) {
            this.selectAreaList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    handler_changeProvince(value) {
      if (value) {
        this.warehouseInfo.province = value;
        this.warehouseInfo.city = undefined;
        this.warehouseInfo.area = undefined;
        this.selectCityList = [];
        this.selectAreaList = [];
        this.initCityList();
      }
    },
    handler_changeCity(value) {
      if (value) {
        this.warehouseInfo.city = value;
        this.warehouseInfo.area = undefined;
        this.selectAreaList = [];
        this.initAreaList();
      }
    }

  }
}
</script>

<style scoped>
.divauto{
  margin:  10px auto 0 auto;
}
</style>