/**
 * Created by xiongxiaomeng.
 * 客户仓库相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    add: params => {  return API.POST('customerwarehouse/add', params)},
    update: params => {  return API.POST('customerwarehouse/update', params)},
    listByCustomerId: params => {  return API.GET('customerwarehouse/listByCustomerId', params)},
    list: params => {  return API.GET('customerwarehouse/list', params)},
    get: params => {  return API.GET('customerwarehouse/get', params)},
    delete: params => {  return API.DELETE('customerwarehouse/delete', params)}
}
