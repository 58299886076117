<!-- 舍弃，暂时不用了 -->
<template>
  <div>
    <div class="pageHeader" style="overflow: hidden; margin-bottom: 10px;">
      <div style="float:left">
        <el-button v-if="auth['customerwarehouse:add']" type="primary" icon="el-icon-plus" @click="handle_add()">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
    </div>
    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="province" label="省份" width="100"></el-table-column>
      <el-table-column prop="city" label="城市" width="100"></el-table-column>
      <el-table-column prop="area" label="区域" width="100"></el-table-column>
      <el-table-column prop="address" label="详细地址" width="400"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button v-if="auth['customerwarehouse:edit']" type="text" icon="el-icon-edit" @click="handle_edit(scope.row)">修改</el-button>
          <el-button v-if="auth['customerwarehouse:del']" type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>

    <formpage :editDigTitle="editTitle" :warehouse-info="editWarehouse" :editDigShow="editShow"></formpage>
  </div>
</template>

<script>
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ORDER from "@/api/biz/api_order";
import WAREHOUSE from "@/api/biz/api_customerwarehouse";
import formpage from "./warehouseEditDialog.vue";
import CUS from "@/api/biz/api_customer";

export default {
  name: "customerwarehouselist",
  components: {
    formpage
  },
  props: {
    customerInfo: {
      type: Object
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      editTitle: '添加仓库',
      editShow: false,
      editWarehouse: {},
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/customer');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      if (this.customerInfo.id==null || this.customerInfo.id=='null') {
        return;
      }

      this.showLoading = true;

      // 执行查询
      WAREHOUSE.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        custid: this.customerInfo.id
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    // 翻页
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    handle_add() {
      this.editTitle = '添加仓库信息';
      this.editWarehouse = {
        custid: this.customerInfo.id
      };
      this.editShow = true;
    },

    handle_edit(row) {
      this.editTitle = '修改仓库信息';
      this.editWarehouse = row;
      this.editShow = true;
    },

    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.address + '] 仓库, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        WAREHOUSE.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    }

  }
}
</script>

<style scoped>

  .red {
    color: #ff0000;
  }
</style>